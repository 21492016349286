import * as React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../components/_global/Layout';

const Section = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;

  p {
    color: #757374;
  }

  ${down('lgmax')} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const SmallContainer = styled.div`
  max-width: 850px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
`;

const LargeContainer = styled.div`
  max-width: 1050px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
`;

const Title = styled.h1`
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  color: #757374;
`;

const Switch = styled.div<{ active: number }>`
  max-width: 750px;
  margin: 35px auto;
  display: flex;
  justify-content: center;
  text-align: center;

  & > div:nth-of-type(1) {
    border-right: none;
  }

  & > div:nth-of-type(2) {
    border-left: none;
  }

  & > div:nth-of-type(${(props) => props.active}) {
    background-color: ${(props) =>
      props.active === 1
        ? props.theme.global.color.blue
        : props.theme.global.color.orange};

    h4 {
      color: ${(props) => props.theme.global.color.white};
    }
  }
`;

const SwitchItem = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  transition: 0.25s ease background-color;
  user-select: none;

  &:nth-of-type(1) {
    border: 1px solid ${(props) => props.theme.global.color.blue};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:nth-of-type(2) {
    border: 1px solid ${(props) => props.theme.global.color.orange};
    border-left: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

const SwitchItemText = styled.h4`
  margin: 0;
  padding: 25px 0;
  color: #757374;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 0 0 48%;
  max-width: 48%;
  width: 100%;

  ${down('mdmax')} {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 35px;

    &:nth-last-of-type(1) {
      padding-bottom: 0;
    }
  }
`;

const ColumnTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 35px;

  & > h1 {
    color: #757374;
  }
`;

const ColumnContent = styled.div`
  p,
  li {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    color: #757374;
  }
`;

const ImageWrapper = styled.div`
  max-width: 150px;
  width: 100%;
  margin-right: 25px;

  ${down('mdmax')} {
    max-width: 175px;
  }
`;

const Covid = ({ data }) => {
  const [activeItem, setActiveItem] = React.useState<number>(1);

  const content = [
    {
      main:
        'Je to tu zas. Zas budeme trávit více času doma, než bychom chtěli. A změní to denní rozvrh nejen nám, ale i našim parťákům. Víš, jak svému mazlíčkovi pomoci lépe snášet změny režimu i tvou neustálou přítomnost?',
      dog_sub:
        'Pes bude mít z naší časté přítomnosti upřímnou radost. Tuto dobu bude brát jako ty volné dny, kdy jeho páníček nikam nechodí a tráví s ním celý den. Takové „prázdniny“ se ale můžou protáhnout i na několik měsíců. A pejsek na nás může brzy začít zkoušet různé kousky ze svého repertoáru neposlušnosti. Jak mu to celé zjednodušit, ale zároveň ho utvrdit v jeho jistotách?',
      dog: [
        {
          strong: 'Dodržuj režim.',
          content:
            'Nepolevuj, ale naopak upevňuj pravidelný režim. Všechny činnosti jako vycházky, trénink nebo jídlo plánuj tak, aby probíhaly vždy ve stejný čas a odpovídaly svým rytmem době, kdy normálně chodíš do práce. Psi pevný rytmus vyžadují, je velmi důležitý pro jejich duševní zdraví i pohodu.',
        },
        {
          strong: 'Cvičte nové dovednosti.',
          content:
            'Pejsci to milují. Jakákoli aktivita s jejich člověkem je pro ně štěstí. Rádi se učí. A teď je ten pravý čas toho využít! Trénuj metodou pozitivní motivace a nezapomeň řádně odměňovat. Pod dohledem si psi mohou také užívat něco na žvýkání a kousání.',
        },
        {
          strong: 'Připomínej pejskovi povel „místo“.',
          content:
            'I když je páníček stále nadosah, má právo na trochu soukromí. A stejně tak i pejsek.',
        },
        {
          strong: 'Nepřeháněj to s vycházkami.',
          content:
            'Doporučujeme vycházky příliš nenavyšovat, aby si na to pejsek nezvykl. Pohyb na čerstvém vzduchu je zdravý a je samozřejmě v pořádku, když nějaká ta procházka během dne přibyde. Více pohybu ale také znamená více odpočinku, který pejsek bude potřebovat.',
        },
        {
          strong: 'Děti jsou součástí smečky.',
          content:
            'Odreagovat se musí všichni. Zapoj své děti i psa do společných her. Jak? Připravte spolu různé schovávačky či hlavolamy – třeba pamlsky schované v zarolovaném ručníku, v kuličkách z papíru. I z tebe či dětí se může stát pochoutková schovka. Nezapomeň, že hru začíná i končí vždycky člověk. ',
        },
      ],
      cat_sub:
        'Kočka je vládce domácnosti a permanentní přítomnost člověka nebo dokonce celé tlupy pro ni může být dočasně nepříjemnou zkušeností. Má ráda svůj prostor, klid a nás lidi může najednou považovat za narušitele svého království. Co dělat, aby si kočka zvykla na naši častější přítomnost?',
      cat: [
        {
          strong: 'Nech jí její prostor.',
          content:
            'Pamatuj, že bydlíš u své kočky, a ne naopak. Je to zkrátka tak. Tam kde je zvyklá se pohybovat, tam se také pohybovat bude. Nezavírej ji do jiné místnosti. Byl by to pro ni stres.',
        },
        {
          strong: 'Respektuj její biorytmus a přání.',
          content:
            'I když ti to tak může připadat, kočce nevadí, že tam jsi. Když tě bude chtít ignorovat, bude tě ignorovat. Nech ji vždy dělat to, co chce. Když chce spát, nech ji spát, a když se chce naopak mazlit, dopřej jí trochu drbání. Určitě ji ale nenuť k interakci v době, kdy o to nejeví zájem. ',
        },
        {
          strong: 'Dodržuj společné rituály.',
          content:
            'Tak jak jste zvyklí vždy ve stejnou dobu. Sice si tu vyšetřenou hodinku, kdy se nemusíš chystat do práce, nepřispíš, ale je to tak lepší pro obě strany. Jaké rituály máme na mysli? Třeba ty budící a mazlící. Přípravu ranního krmení. Odpolední svačinka i večerní hry. ',
        },
        {
          strong: 'Pozor na děti.',
          content:
            'Děti budou mít tendence se v mezičase kočce více „věnovat“. Vysvětli jim, že kočka potřebuje svůj režim. Že potřebuje hodně spát, mít klid a prostor, a že mazlit se a hrát si s ní je možné jen až ona sama bude chtít. Kočku nemůžeme do ničeho nutit. Častější kontakt jí nemusí být vždy příjemný.',
        },
      ],
    },
    {
      main:
        'Co dělat, když je lidem zas povolena volnost? Parťáci si zvykli na naši neustálou přítomnost, častější procházky i nové rituály. S koncem omezení se všichni těšíme, že se vše vrátí do starých kolejí. Ale pro naše chlupaté společníky to může znamenat změny, na které nejsou připravení. Víš, jak svého mazlíčka připravit na návrat do normálu?',
      dog_sub:
        'Příprava pejska na normální život je velmi důležitá. Pes je vysoce sociální zvíře, pro které může být odloučení od „smečky“ stresující. Pejsek v takové situaci umí svůj smutek dát hlasitě najevo. A to pak může být stresující i pro sousedy. Jak pomoci parťákovi přivyknout novému režimu?',
      dog: [
        {
          strong: 'Zpevni denní program.',
          content:
            'Všechny činnosti jako vycházky, trénink nebo jídlo plánuj tak, aby probíhaly ve stejný čas a odpovídaly svým rytmem období, kdy normálně chodíte do práce, nebo fungujete mimo společný domov. Pevný režim je pro duševní zdraví a pohodu pejska velmi důležitý.',
        },
        {
          strong: 'Dejte si víc času a po ránu „šejpujte“.',
          content:
            'Vyzkoušej metodu „shaping“ neboli tvarování. To je technika, kdy páníček čeká na správnou odpověď psa. Pejsci, kteří vědí, o co jde, se snaží nabízet různé odpovědi, aby za ně dostali svůj pamlsek. Odměnu dostanou ve chvíli, kdy se pánovi odpověď líbí. Shaping je náročný na duševní práci a pejska spolehlivě unaví. Dobu osamění pak pejsek v pohodě prospí. ',
        },
        {
          strong: 'Nauč ho znovu samotě.',
          content:
            'Nejdříve jen na pár chvil zavři pejska do druhé místnosti, kde bude sám. Můžeš na chvilku odejít z bytu nebo domu a po pár minutách se vrátit. Po příchodu pejska hodně pochválíme a odměníme. Tyto intervaly postupně prodlužujeme až asi na 30 minut. Pokud pejsek vydrží třicet minut sám, vydrží už prakticky jakkoliv dlouhé odloučení. ',
        },
        {
          strong: 'Zopakujte si čekání.',
          content:
            'Velmi dobře se v této situaci uplatňuje např. kennelka, větší pejsky je dobré oddělit např. na chodbu. Pejsek si musí zvyknout na jedno konkrétní místo, kde bude na svého člověka čekat.',
        },
        {
          strong: 'Naučte se znovu loučit.',
          content:
            'Několik dnů před změnou životního rytmu se na stejném místě s pejskem rozloučíme a tady bude probíhat i vítání. Je proto dobré, je-li toto místo někde blízko vchodu. Nepoužívej povely, jako „zůstaň“ nebo „čekej“. Ty po odchodu není možné zrušit. Používej slova jako „ahoj“ a nezapomeň na pamlsek.',
        },
      ],
      cat_sub:
        'Kočky jsou velmi citlivé na veškeré změny. Mohou na ně dokonce reagovat zhoršením zdravotního či psychického stavu. Jde v celku o běžnou reakci, která většinou brzy pomine. Těmito kroky jí můžeš předcházet.',
      cat: [
        {
          strong: 'Vytvoř jí zázemí.',
          content:
            'Místo, které bude jen její. Může to být bedýnka, krabice, kennelka. Místo jistoty, kam si kdykoliv může zalézt a schovat se při zvykání si na nové podmínky. ',
        },
        {
          strong: 'Změny dávkuj postupně.',
          content:
            'Pomalu přecházej na dříve známé rituály, a to ve stejnou dobu, tak jak jste bývali zvyklí. Příprava ranního krmení. Mazlící a budící rituál. Odpolední svačinka v dobu, kdy se normálně vracíš domů.',
        },
        {
          strong: 'Kočku pomalu uč samotě.',
          content:
            'Ze začátku stačí několikrát za den trávit krátký čas třeba v jiné místnosti. Na chvilku odejdi a kočku nech v jejím prostředí. Naučí se tak, že je vše v pořádku, i když její člověk na chvilku „zmizí“. V žádném případě kočku nepřenášej a nezavírej do jiné místnosti. Brala by to jako trest a mohla by následovat krutá kočičí pomsta!',
        },
        {
          strong: 'Odměňuj. ',
          content:
            'I když se to nezdá, kočky nejsou samotáři, tvůj čas a lásku potřebují. Nezapomeň jí vždy po delším či kratším odloučení svou lásku připomenout v podobě pamlsku a interakce s ní.',
        },
        {
          strong: 'Sežeň hračku s kozlíkem lékařským.',
          content:
            'Tato bylinka dokáže divy. Kočku při mazlení hračka zklidní a uvolní.',
        },
        {
          strong: 'Dvě jsou víc než jedna.',
          content:
            'Pokud máš kočky dvě, znamená to o polovinu méně starostí! Kočka už má svého rovnocenného parťáka, který je stále přítomen a tvé „mizení“ možná ani nezaznamená. ',
        },
      ],
    },
  ];

  return (
    <Layout>
      <Section>
        <SmallContainer>
          <Title>Mazlíčci v době covidu</Title>
          <p>
            Jsme sice optimisté, ale nepodceňujeme rčení „štěstí přeje
            připraveným“. A tak jsme připravili návod, jak zvládat změny režimu
            se svým parťákem v případě lockdownu i rozvolňování.
          </p>
          <p>
            Pro naše chlupáče jsou to totiž nečekané změny. Neví, proč je
            páníček najednou skoro pořád doma, proč chce dělat věci, na které
            dřív neměl čas nebo proč je najednou zase přes den pryč. A tak je na
            nás jim pomoci tuhle zvláštní dobu překonat.
          </p>
          <Switch active={activeItem}>
            <SwitchItem
              onClick={() => setActiveItem((prevState) => (prevState = 1))}
            >
              <SwitchItemText>
                JAK ZVLÁDNOUT
                <br /> LOCKDOWN
              </SwitchItemText>
            </SwitchItem>
            <SwitchItem
              onClick={() => setActiveItem((prevState) => (prevState = 2))}
            >
              <SwitchItemText>
                JAK ZVLÁDNOUT
                <br /> ROZVOLŇOVÁNÍ
              </SwitchItemText>
            </SwitchItem>
          </Switch>
          <p style={{ marginBottom: 0, paddingBottom: 35 }}>
            {content[activeItem - 1].main}
          </p>
        </SmallContainer>
        <LargeContainer>
          <Flex>
            <Column>
              <ColumnTitle>
                <ImageWrapper>
                  <Image fluid={data.dog.childImageSharp.fluid} />
                </ImageWrapper>
                <h1>PSI</h1>
              </ColumnTitle>
              <ColumnContent>
                <p>{content[activeItem - 1].dog_sub}</p>
                <ul>
                  {content[activeItem - 1].dog.map((item, index) => (
                    <li key={index}>
                      <strong>{item.strong}</strong> {item.content}
                    </li>
                  ))}
                </ul>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnTitle>
                <ImageWrapper>
                  <Image fluid={data.cat.childImageSharp.fluid} />
                </ImageWrapper>
                <h1>KOČKY</h1>
              </ColumnTitle>
              <ColumnContent>
                <p>{content[activeItem - 1].cat_sub}</p>
                <ul>
                  {content[activeItem - 1].cat.map((item, index) => (
                    <li key={index}>
                      <strong>{item.strong}</strong> {item.content}
                    </li>
                  ))}
                </ul>
              </ColumnContent>
            </Column>
          </Flex>
        </LargeContainer>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    dog: file(relativePath: { eq: "images/homepage/dog_icon.jpg" }) {
      ...imagesFragment
    }
    cat: file(relativePath: { eq: "images/homepage/cat_icon.jpg" }) {
      ...imagesFragment
    }
  }
`;

export default Covid;
